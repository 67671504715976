import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector, inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, Observable, throwError } from 'rxjs';
import { Globals } from '../globals';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
/*
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    //isRefreshingToken = false;

    constructor(private snackBar: MatSnackBar) { }
//export function authInterceptor(snackBar: MatSnackBar): (req: HttpRequest<any>, next: HttpHandlerFn) => Observable<HttpEvent<any>> {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = inject(AuthService);
    // Response
        return next.handle(request).pipe(
            //return next.handle(req).pipe(
            map(evt => {
                console.log('test');
                if (evt instanceof HttpResponse) {
                    if ((evt.body) && ((evt.body as any).status)) {
                        switch ((evt.body as any).status.type) {
                            case 'E':
                                console.log('wyrzucanie snackBar');
                                this.snackBar.open((evt.body as any).status.text, 'OK', { verticalPosition: 'top' });
                                break;
                            case 'W':
                                this.snackBar.open((evt.body as any).status.text, 'OK',
                                    { verticalPosition: 'top', duration: 3000 });
                                break;
                            case 'I':
                                this.snackBar.open((evt.body as any).status.text, 'OK',
                                    { verticalPosition: 'top', duration: 3000 });
                        }
                        return evt.clone({ body: (evt.body as any).data });
                    }
                }
                return evt;
            }),
            catchError((error) => {
                console.log('eerrrr');
                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // Sign out
                    authService.signOut();

                    // Reload the app
                    location.reload();
                }

                return throwError(error);
            }),
        );
    }
}
*/


export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    //const snackBar = new MatSnackBar();
    //const snackBar = authService.appInjector.get(MatSnackBar);
    //const snackBar = authService.snackBar;
    /*const snackBar = Injector.create({
        providers: [{ provide: MatSnackBar, deps: [] }],
        parent: authService.appInjector
      }).get(MatSnackBar);*/
    // Response
//    return (req: HttpRequest<any>, next: HttpHandlerFn) => {
        return next(req).pipe(
            //return next.handle(req).pipe(
            map(evt => {
                if (evt instanceof HttpResponse) {
                    if ((evt.body) && ((evt.body as any).status)) {
                        switch ((evt.body as any).status.type) {
                            case 'E':
                                authService.error((evt.body as any).status.text);
                                break;
                            case 'W':
                                authService.error((evt.body as any).status.text);
                                break;
                            case 'I':
                                authService.error((evt.body as any).status.text);
                        }
                        return evt.clone({ body: (evt.body as any).data });
                    }
                }
                return evt;
            }),
            catchError((error) => {
                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // Sign out
                    authService.signOut();
                    // Reload the app
                    location.reload();
                }

                if (error instanceof HttpErrorResponse && error.status == 400) {
//                    console.log(error);
                        authService.error(error.error);

/*                    if (evt instanceof HttpResponse) {
                        if ((evt.body) && ((evt.body as any).status)) {
                            switch ((evt.body as any).status.type) {
                                case 'E':
                                    console.log('wyrzucanie snackBar');
                                    authService.error((evt.body as any).status.text);
                                    break;
                            }
                        }
                    }    */
                }
                return throwError(error);
            }),
        );
    }

import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { CookieService } from 'ngx-cookie-service';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthService
{
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _cookieService: CookieService,
        private injector: Injector,
        private matSnackBar: MatSnackBar
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set sessionLifetime(session_lifetime: string)
    {
        localStorage.setItem('session_lifetime', session_lifetime);
    }

    get sessionLifetime(): string
    {
        return localStorage.getItem('session_lifetime') ?? '';
    }

    get appInjector(): Injector
    {
        return this.injector;
    }

    get snackBar(): MatSnackBar
    {
        return this.matSnackBar;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    error(message: string) {
        return this.snackBar.open(message, 'OK', { verticalPosition: 'top', panelClass: 'snackbar-error' });
    }
    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Change password
     *
     * @param password
     */
    changePassword(prev_password: string, new_password: string): Observable<any>
    {
        return this._httpClient.post('backend/user/change-password', { current: prev_password, new: new_password });
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('backend/user/signin', credentials).pipe(
            switchMap((response: any) =>
            {
                // Store the access token in the local storage
                //this.sessionLifetime = response.session_lifetime;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            }),
        );
    }

    /**
     * Sign in using the access token
     */
    /*signInUsingToken(): Observable<any>
    {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken,
        }).pipe(
            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if ( response.accessToken )
                {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            }),
        );
    }*/

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        return this._httpClient.get('backend/user/signout').pipe(
            switchMap((response: any) => {
                //localStorage.removeItem('session_lifetime');

                // Set the authenticated flag to false
                this._authenticated = false;
                // Return the observable
                return of(true);
            }),
        );
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('backend/user/signup', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        let user = this._cookieService.get('user')
        if (!user) {
            //localStorage.removeItem('username');
            this._authenticated = false;
            return of(false);
        } else {
            this._userService.user = JSON.parse(user);
            return of(true);
        }
    }
}

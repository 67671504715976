import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'app/core/auth/auth.service';
import { authInterceptor } from './auth.interceptor';
//import { authInterceptor } from './auth.interceptor';

export const provideAuth = (): Array<Provider | EnvironmentProviders> =>
{
    return [//[provideHttpClient(withInterceptors([authInterceptor(snackBar: MatSnackBar)])),
        /*{ 
        provide: HTTP_INTERCEPTORS, 
        useFactory: (snackBar: MatSnackBar) => authInterceptor(snackBar), 
        deps: [MatSnackBar], 
        multi: true 
      },*/
        provideHttpClient(withInterceptors([authInterceptor])),
        //provideHttpClient(withInterceptorsFromDi()),
        
        {
            provide : ENVIRONMENT_INITIALIZER,
            useValue: () => inject(AuthService),
            multi   : true,
        },
    ];
};

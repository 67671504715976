import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, UserData, UserList, UserPrivileges } from 'app/core/user/user.types';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserService
{
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    list(searchString:string, page: number, size: number): Observable<UserList>
    {
        return this._httpClient.get<UserList>('backend/user/list/search/' + searchString + '/page/' + page + '/size/' + size);
    }

    listPrivileges(id: Number): Observable<UserPrivileges[]>
    {
        return this._httpClient.get<UserPrivileges[]>('backend/user/list/privileges/user/' + id.toString());
    }

    get(id: Number): Observable<UserData>
    {
        return this._httpClient.get<UserData>('backend/user/get/id/' + id.toString());
    }

    deactivate(id: Number): Observable<Number>
    {
        return this._httpClient.delete<Number>('backend/user/deactivate/id/' + id.toString());
    }

    save(user: UserData): Observable<UserData>
    {
        return this._httpClient.post<UserData>('backend/user/save', JSON.stringify(user));
    }

    savePrivileges(id: Number, user_privileges: UserPrivileges[]): Observable<UserPrivileges[]>
    {
        return this._httpClient.post<UserPrivileges[]>('backend/user/save_privileges/' + id.toString(), JSON.stringify(user_privileges));
    }

    resetPassword(id: Number): Observable<any>
    {
        return this._httpClient.post('backend/user/reset-password', { us_id: id.toString() });
    }

}
